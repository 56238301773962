import { combineReducers } from "redux";
import commonReducer from "./common/commonSlice";
import CommonState from "./common/CommonState";
import exceptionsReducer from "./exceptions/exceptionsSlice";
import ExceptionsState from "./exceptions/ExceptionsState";
import pickupsReducer from "./pickups/pickupsSlice";
import PickupsState from "./pickups/PickupsState";
import routeMapReducer from "./route-map/routeMapSlice";
import RouteMapState from "./route-map/RouteMapState";
import routeSelectReducer from "./route-select/routeSelectSlice";
import RouteSelectState from "./route-select/RouteSelectState";
import routeSummaryReducer from "./route-summary/routeSummarySlice";
import RouteSummaryState from "./route-summary/RouteSummaryState";
import stopDetailsReducer from "./stop-details/stopDetailsSlice";
import StopDetailsState from "./stop-details/StopDetailsState";
import userReducer from "./user/userSlice";
import UserState from "./user/UserState";
import { deliverShipmentReducer } from "./stop-details/deliverShipmentSlice";
import { DeliverShipmentState } from "./stop-details/deliverShipmentState";
import { PickupShipmentState } from "./stop-details/pickupShipmentState";
import pickupShipmentReducer from "./stop-details/pickupShipmentSlice";
import { NotifyConsigneeState } from "./notify-customer/notifyConsigneeState";
import notifyConsigneeReducer from "./notify-customer/notifyCustomerSlice";
import webClockReducer from "./web-clock/webClockSlice";
import WebClockState from "./web-clock/webClockState";
import { PreviewImageState } from "./preview-image/previewImageState";
import previewImageReducer from "./preview-image/previewImageSlice";

export interface BaseState {
  loading: boolean;
  loadingFailed: boolean;
  loadingError?: string | null;
  loaded: boolean;
};

export interface IState {
  common: CommonState;
  deliverShipment: DeliverShipmentState;
  exceptions: ExceptionsState;
  pickups: PickupsState;
  routeMap: RouteMapState;
  routeSelect: RouteSelectState;
  routeSummary: RouteSummaryState;
  stopDetails: StopDetailsState;
  user: UserState;
  pickupShipment: PickupShipmentState;
  notifyConsignee: NotifyConsigneeState;
  webClock: WebClockState;
  previewImage: PreviewImageState;
};

const appReducer = combineReducers({
  common: commonReducer,
  deliverShipment: deliverShipmentReducer,
  exceptions: exceptionsReducer,
  pickups: pickupsReducer,
  routeMap: routeMapReducer,
  routeSelect: routeSelectReducer,
  routeSummary: routeSummaryReducer,
  stopDetails: stopDetailsReducer,
  user: userReducer,
  pickupShipment: pickupShipmentReducer,
  notifyConsignee: notifyConsigneeReducer,
  webClock: webClockReducer,
  previewImage: previewImageReducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer((action.type === "FULL_RESET") ? { user: state.user } : state, action);
};

export default rootReducer;
