import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialPreviewImageState } from "./previewImageState";

export const previewImageSlice = createSlice({
  name: "previewImage",
  initialState: initialPreviewImageState,
  reducers: {
    resetPreviewImageState: (state) => initialPreviewImageState,
    setImageForPreview: (state, { payload }) => {
      state.imageUrl = payload;
    },
  }
});

export const {
  resetPreviewImageState,
  setImageForPreview
} = previewImageSlice.actions;

export const previewImageSelector = (state: IState) => {
  return state.previewImage;
};

const previewImageReducer = previewImageSlice.reducer;
export default previewImageReducer;
