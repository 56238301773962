import React from "react";
import "./previewImage.scss";
import { useSelector } from "react-redux";
import { previewImageSelector, setImageForPreview } from "../../slices/preview-image/previewImageSlice";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import { useDispatch } from "react-redux";
// @ts-ignore
import styles from "../../styles/variables.scss";

export const PreviewImage: React.FC<{}> = () => {
  const previewImageState = useSelector(previewImageSelector);
  const dispatch = useDispatch();

  if (!previewImageState.imageUrl) return null;

  return (
    <div 
      onClick={(e) => {
        e.stopPropagation()
        dispatch(setImageForPreview(null))
      }} 
      className="xgs-preview-image"
    >
      <div
        className="xgs-preview-image__control"
        onClick={() => dispatch(setImageForPreview(null))}
      >
        <XGSIcon
          icon={XGSIcons.faTimes}
          height={"30px"}
          color={styles.red}
        />
      </div>
      <img onClick={(e) => e.stopPropagation()} src={previewImageState.imageUrl} alt="preview"/>
    </div>
  )
}