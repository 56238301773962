import React, { useState } from "react";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import { ACCEPTED_FORMATS, MAX_SIZE } from "../exceptions/constants";
import { useDropzone } from "react-dropzone";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import Button, { ButtonThemes } from "../../components/button/button";
import "./completePickupModal.scss";
import { toast } from "react-toastify";
import { updateCacheForCompletedPickup } from "../../app/common/statusUpdater";
import { getRouteSummary } from "../../slices/route-summary/routeSummarySlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { getAllStopsDetails, stopDetailsSelector } from "../../slices/stop-details/stopDetailsSlice";
import { getRouteMapData } from "../../slices/route-map/routeMapSlice";
import { pickupShipmentSelector, submitCompletePickup } from "../../slices/stop-details/pickupShipmentSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app/route/RoutesConfig";
import { useSelector } from "react-redux";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import LabeledMaskInput from "../../components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import { Formik, FormikProps, Form } from "formik";
import { SubmitPickupRequestSchema } from "../../app/data/stop-details/models";
import { pickupsSelector } from "../../slices/pickups/pickupsSlice";
import { setImageForPreview } from "../../slices/preview-image/previewImageSlice";
import { processDocument } from "../../app/common/documentScanner";

export interface CompletePickupModalProps {
  manifestNumber: number;
  show: boolean;
  onClose: () => void;
  pickupNumber: string;
  stopNumber: number;
}

const initialFormValues = {
  probill: ""
}

export const CompletePickupModal: React.FC<CompletePickupModalProps> = (props) => {
  const [photos, setPhotos] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pickupShipmentState = useSelector(pickupShipmentSelector);
  const stopDetailsState = useSelector(stopDetailsSelector);
  const pickupsState = useSelector(pickupsSelector);
  const [probill, setProbill] = useState("");

  const currentStopDetails = props.manifestNumber
    ? (stopDetailsState.details ? stopDetailsState.details[props.stopNumber - 1] : null)
    : ((pickupsState.pickups?.length > 0) ? pickupsState.pickups[props.stopNumber - 1] : null);

  const onSuccess = () => {
    toast.info("The pickup has been completed!");
    if (props.manifestNumber) {
      dispatch(getRouteSummary(props.manifestNumber));
      dispatch(getAllStopsDetails(props.manifestNumber));
      dispatch(getRouteMapData(props.manifestNumber));
    }
    props.onClose();
    navigate(props.manifestNumber ? `/${props.manifestNumber}${ROUTES.route}` : "/");
  }
  
  const onOffline = () => {
    toast.info("Pickup will be completed when internet is back online!")
    updateCacheForCompletedPickup(props.manifestNumber, props.stopNumber);
    if (props.manifestNumber) {
      dispatch(getRouteSummary(props.manifestNumber));
      dispatch(getAllStopsDetails(props.manifestNumber));
      dispatch(getRouteMapData(props.manifestNumber));
    }
    props.onClose();
    navigate(props.manifestNumber ? `/${props.manifestNumber}${ROUTES.route}` : "");
  }

  const removePhoto = (i: number) => {
    let newArr = [...photos];
    newArr[i].deleted = true;
    setPhotos(newArr);
  };
  
  const photosBlock = photos.map((photo: any, i: number) => (
    <div
      className={`xgs-complete-pickup__form__photos__item ${photo.deleted ? "xgs-complete-pickup__form__photos__item--deleted" : ""}`}
      key={`photo.name-${i}`}
      onClick={() => {
        dispatch(setImageForPreview(photo.preview))
      }}
    >
      <div className="xgs-complete-pickup__form__photos__item__image">
        <img
          id=""
          src={photo.preview}
          width={140}
          alt="Preview"
        />
        <div
          className="xgs-complete-pickup__form__photos__item__image__control"
          onClick={(e) => {
            e.stopPropagation();
            removePhoto(i);
          }}
        >
          <XGSIcon
            icon={XGSIcons.faTimes}
            className="xgs-complete-pickup__form__photos__item__icon"
          />
        </div>
      </div>
    </div>
  ));

  const [fileError, setFileError] = useState<string>("");

  const MAX_PHOTOS = 4;
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS - photos.filter((obj: any) => !obj.deleted).length,
    onDrop: async (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats (JPEG, PNG, WebP, GIF and BMP) are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;

      // @ts-ignore-next-line
      if (!!window.cv) {
        for (let idx = 0; idx< acceptedFiles.length; idx++) {
          acceptedFiles[idx] = await processDocument(acceptedFiles[idx]) as any;
        }
      } else {
        console.log("OpenCV has not been loaded, documents not cropped");
      }
  
      setPhotos([...photos, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        deleted: false,
        comment: "",
        internalOnly: false
      }))]);
    }
  });

  const onSubmit = () => {
    const fd = new FormData();
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }

    const preparedData = {
      pickupNumber: props.pickupNumber,
      probillNumber: probill
    };

    fd.append("data", JSON.stringify(preparedData));
    dispatch(submitCompletePickup(fd,
      onSuccess,
      onOffline))
  }
  
  return (
    <SlideInModal show={props.show} title={`Complete pickup: ${props.pickupNumber}`} onClose={props.onClose}>
      <div className="xgs-complete-pickup">
        <Formik validationSchema={SubmitPickupRequestSchema} onSubmit={() => onSubmit()} initialValues={initialFormValues}>
          {(props: FormikProps<{probill: string}>) => (
            <Form>
              <LabeledMaskInput
                name="probill"
                value={probill}
                onValueChange={(e) => {
                  setProbill(e);
                  props.setFieldValue("probill", e);
                }}
                format="########"
                className="xgs-complete-pickup__probill-input"
                label="Probill number:"
                labelMode={LabelModes.column}
                inputMode="numeric"
              />
              <div className="xgs-complete-pickup__form__upload">
                <div className="xgs-form__label">Photos:</div>
                <>
                  {(photos.filter((obj: any) => !obj.deleted).length < MAX_PHOTOS) && (
                    <div {...getRootProps({ className: "xgs-upload__area" })}>
                      <input {...getInputProps()} capture="environment" />
                      <XGSIcon
                        icon={XGSIcons.faCamera}
                        className="xgs-upload__area__icon"
                      />
                      <span className="blue-link">Tap to take or attach a photo</span>
                      {fileError && (
                        <span className="xgs-upload__area__error">
                          {fileError}
                        </span>
                      )}
                    </div>
                  )}
                </>
              </div>
              {(photos.length > 0) && (
                <div className="xgs-complete-pickup__form__photos">
                  {photosBlock}
                </div>
              )}
              {currentStopDetails?.specialInstructions && <p className="xgs-complete-pickup__special"><b>Special instructions: </b>{currentStopDetails.specialInstructions}</p>}
              <Button spinner={pickupShipmentState.requestStarted} className="xgs-complete-pickup__submit" theme={ButtonThemes.blue} >Submit</Button>
            </Form>
          )}
        </Formik>

      </div>
    </SlideInModal>
  )
}
