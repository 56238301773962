
import { createSlice } from "@reduxjs/toolkit";

import dayjs from "dayjs";
import { findLast } from "lodash";
import { AppThunk } from "../../app/store";
import { IState } from "..";

import { PaylocityRecordTypes, WebClockBaseRecordModel, PunchTypes } from "../../app/data/web-clock/models";
import PunchDetails from "../../app/data/web-clock/punchDetails";
import { getTz } from "../../app/data/common/getTz";
import { initialWebClockState } from "./webClockState";
import WebClockService from "../../app/data/web-clock/webClockService";
import { ERROR } from "../../app/data/common/errors";

const webClockService = WebClockService.getInstance();

export const webClockSlice = createSlice({
  name: "webClock",
  initialState: initialWebClockState,
  reducers: {
    setPunchDetails: (state, { payload }) => {
      state.punchDetails = payload;
    },
    requestStarted: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: true,
        requestSucceed: false,
        requestFailed: false,
        requestFailReason: null,
      };
    },
    requestSucceed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: true,
        requestFailed: false,
      };      
    },
    requestFailed: (state, { payload }) => {
      state.request[payload.requester] = {
        requestStarted: false,
        requestSucceed: false,
        requestFailed: true,
        requestFailReason: payload.error,
      };
    },
    updatePunchDetails: (state, { payload }) => {
      state.punchDetails = payload
    },
    setLocationId: (state, { payload }) => {
      state.locationId = payload;      
    },
    setLocations: (state, { payload }) => {
      state.locations = payload;
    }
  }
});

export const {
  setPunchDetails,
  requestStarted,
  requestSucceed,
  requestFailed,
  updatePunchDetails,
  setLocationId,
  setLocations,
} = webClockSlice.actions;

export const webClockSelector = (state: IState) => {
  return state.webClock;
};

export const currentLocationSelector = (state: IState) => {
  const { webClock, user } = state;
  const id = webClock.locationId || user.profile?.terminalNumber;
  return webClock.locations.find(location => location.terminalCode === Number(id));
};

const updateCache = async (punchDetails: any, employeeId: number) => {
  const cache = await caches.open("xgs-driver-app-api-responses");  

  // Update cache
  await cache.put(process.env.REACT_APP_API_BASE_URL + `/paylocity/punchDetails/${employeeId}`, new Response(
    JSON.stringify(punchDetails), {
    headers: {
      "Content-Type": "application/json"
    }
  }
  ));
};

const getNewPunchDetails = (currentDetails: any, newRecords: any[]) => {
  const punchDetails = new PunchDetails();
  punchDetails.details = currentDetails;

  newRecords.forEach(newRecord => {
    const datetime = `${newRecord.date}T${newRecord.time}:00`;    

    switch (newRecord.recordType) {
      case PaylocityRecordTypes.IN:
        punchDetails.details = [];
        punchDetails.addPunch(datetime, PunchTypes.WORK, newRecord.costCenter);
        break;
      case PaylocityRecordTypes.OUT:        
        punchDetails.closePunch(datetime);
        break;
      case PaylocityRecordTypes.START_LUNCH:
        punchDetails.closePunch(datetime);
        punchDetails.addPunch(datetime, PunchTypes.LUNCH, newRecord.costCenter);
        break;
      case PaylocityRecordTypes.END_LUNCH:
        punchDetails.closePunch(datetime);
        punchDetails.addPunch(datetime, PunchTypes.WORK, newRecord.costCenter);
    }
  });

  return punchDetails.details;
}

export const addClockRecords = (
  records: WebClockBaseRecordModel[],
  onSuccess?: () => void,
  onFailed?: (error: string) => void,
  onOffline?: () => void,
): AppThunk => async (dispatch, getState) => {
  dispatch(requestStarted({ requester: "ADD_CLOCK_RECORD" }));

  const { webClock: { locationId, punchDetails, locations }, user: { profile } } = getState();

  const id = locationId || profile?.terminalNumber;
  const location = locations.find(item => item.terminalCode === Number(id));

  if (!location || !profile) {
    onFailed?.(!location ? "Location should be selected" : ERROR.COMMON);
    return;
  }

  const now = dayjs().tz(getTz(location.terminalZone));
  const date = now.toString().toApiDateFormat();
  const hour = now.hour();
  const minute = now.minute();
  const time = `${hour}:${minute.toString().padStart(2, '0')}`;

  const newRecords = records.map(record => ({
    recordType: record.recordType,
    costCenter: record.costCenter || locationId || profile.terminalNumber,
    date,
    time,
    employeeId: profile.employeeId,
  }));

  const newPunchDetails = getNewPunchDetails(punchDetails, newRecords);
  
  const response = await webClockService.addClockRecords(newRecords);

  if (navigator.onLine) {    
    if (response.ok()) {
      dispatch(updatePunchDetails(newPunchDetails));      
      dispatch(requestSucceed({ requester: "ADD_CLOCK_RECORD" }));
      onSuccess?.();
    } else {
      dispatch(requestFailed({ requester: "ADD_CLOCK_RECORD", error: response.getError?.() || ERROR.COMMON }));
      onFailed?.(response.getError?.() || ERROR.COMMON);
    }
  } else {
    dispatch(requestSucceed({ requester: "ADD_CLOCK_RECORD" }));
    dispatch(updatePunchDetails(newPunchDetails));
    updateCache(newPunchDetails, profile.employeeId);
    onOffline?.();
  }
};

export const getLocations = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted({requester: "GET_LOCATIONS"}));
  const response = await webClockService.getLocations();
  if (response.ok()) {
    dispatch(requestSucceed({requester: "GET_LOCATIONS"}));
    dispatch(setLocations(response.data));
  } else {
    dispatch(requestFailed({requester: "GET_LOCATIONS", error: response.getError && response.getError()}));
  }
};

export const getClockRecords = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted({requester: "GET_CLOCK_RECORDS"}));
  const response = await webClockService.getClockRecords();

  if (response.isCancel?.()) return;

  if (response.ok()) {
    dispatch(requestSucceed({requester: "GET_CLOCK_RECORDS"}));
    const data = response.data.filter(punch => punch.relativeStart);
    dispatch(setPunchDetails(data));

    const terminalCode = findLast(response.data, punch => !!punch.costCenters)?.costCenters?.find(cc => cc.level === 1)?.code;
    dispatch(setLocationId(Number(terminalCode)));
  } else {
    dispatch(requestFailed({requester: "GET_CLOCK_RECORDS", error: response.getError && response.getError() }));
  }
};

const webClockReducer = webClockSlice.reducer;
export default webClockReducer;
