import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/storeHooks";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import Button, { ButtonThemes } from "../../components/button/button";
import {
  stopDetailsSelector,
  arriveAtDeliveryStop,
  arriveAtPickupStop
} from "../../slices/stop-details/stopDetailsSlice";
import arrowIcon from "../../images/arrow-left_gray.svg";
import checkIcon from "../../images/check_gray.svg";
import "./arriveAtStop.scss";

interface Props {
  manifestNumber: number;
  stopNumber: number;
  pickupNumber: string | null;
  type: string;
  address: string;
  show: boolean;
  onArrive: () => void;
  onCancel: () => void;
};

interface LocationObjModel {
  longitude: number;
  latitude: number;
};

dayjs.extend(utc);

const ArriveAtStop: React.FC<Props> = ({ manifestNumber, stopNumber, pickupNumber, type, address, show, onArrive, onCancel }) => {
  const dispatch = useAppDispatch();
  const stopDetailsState = useSelector(stopDetailsSelector);

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        location => resolve({
          longitude: location.coords.longitude,
          latitude: location.coords.latitude
        }),
        error => reject(error)
      );
    });
  };

  const showErrorToast = () => toast.error(stopDetailsState.requester === "ARRIVE_AT_STOP" && (stopDetailsState.requestFailReason || "Error! Please try later or contact support."));

  const onClickArrived = async () => {
    let location = {
      longitude: 0,
      latitude: 0
    };
    try {
      location = await getLocation() as LocationObjModel;
    } catch (err) {
      toast.error("Geolocation is currently disabled on your device. Please enable it in your settings or contact support for assistance. ", {
        autoClose: 10000
      });
      console.error(err);
    }
    if (type === "DELIVERY") {
      const request = {
        routeStop: stopNumber,
        manifest: manifestNumber,
        arrivalTime: dayjs.utc().format(),
        ...location
      };
      dispatch(arriveAtDeliveryStop(request, onArrive, showErrorToast));
    } else if (type === "PICKUP" && pickupNumber) {
      const request = {
        pickupNumber: pickupNumber,
        arrivalTime: dayjs.utc().format(),
        ...location
      };
      dispatch(arriveAtPickupStop(stopNumber, request, onArrive, showErrorToast));
    }
  };

  return (
    <SlideInModal
      title="Confirm Arrival"
      show={show}
      onClose={onCancel}
      contentClassName="xgs-arrive-at-stop__dialog-content"
    >
      <div className="xgs-arrive-at-stop__header">Have you arrived at your stop?</div>
      <div className="xgs-arrive-at-stop__text">
        If you are at <strong>{address}</strong>, tap &quot;Arrived&quot; to confirm.<br />
        If you are not at this location, tap &quot;Back&quot; to return to the previous screen.
      </div>
      <div className="xgs-sign-shipment__buttons">
        <Button
          type="button"
          onClick={onCancel}
          theme={ButtonThemes.gray}
          className="xgs-arrive-at-stop__button xgs-arrive-at-stop__button--back"
        >
          <img src={arrowIcon} alt="arrow" /> Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          onClick={onClickArrived}
          spinner={stopDetailsState.requestStarted && stopDetailsState.requester === "ARRIVE_AT_STOP"}
          className="xgs-arrive-at-stop__button xgs-arrive-at-stop__button--reached"
        >
          {!(stopDetailsState.requestStarted && stopDetailsState.requester === "ARRIVE_AT_STOP") && <img src={checkIcon} alt="check" />} Arrived
        </Button>
      </div>
    </SlideInModal>
  );
};

export default ArriveAtStop;
