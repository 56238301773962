import { createSlice } from "@reduxjs/toolkit";
import { ArrivalAtDeliveryStopRequest, ArrivalAtPickupStopRequest } from "../../app/data/stop-details/models";
import StopDetailsService from "../../app/data/stop-details/stopDetailsService";
import { initialStopDetailsState } from "./StopDetailsState";
import { AppThunk } from "../../app/store";
import { IState } from "..";

const stopDetailsService = StopDetailsService.getInstance();

export const stopDetailsSlice = createSlice({
  name: "stopDetails",
  initialState: initialStopDetailsState,
  reducers: {
    resetStopDetails: (state) => state = initialStopDetailsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setStopsDetails: (state, { payload }) => {
      state.details = payload;
    },
    setStopReached: (state, { payload }) => {
      if (!state.details) return;
      const i = state.details.findIndex(stop => stop.order === payload);
      if (i === -1) return;
      state.details[i].reached = true;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setStopsDetails,
  resetStopDetails,
  setStopReached
} = stopDetailsSlice.actions;

export const stopDetailsSelector = (state: IState) => state.stopDetails;

export const getAllStopsDetails = (
  manifestNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_ALL_STOPS_DETAILS"))
  const response = await stopDetailsService.getAllStopsDetails(manifestNumber);
  if (response.ok()) {
    dispatch(setStopsDetails(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

export const arriveAtDeliveryStop = (
  request: ArrivalAtDeliveryStopRequest,
  onSuccess: () => void,
  onFail: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ARRIVE_AT_STOP"))
  const response = await stopDetailsService.arriveAtDeliveryStop(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setStopReached(request.routeStop));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFail();
  }
};

export const arriveAtPickupStop = (
  routeStop: number,
  request: ArrivalAtPickupStopRequest,
  onSuccess: () => void,
  onFail: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ARRIVE_AT_STOP"))
  const response = await stopDetailsService.arriveAtPickupStop(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setStopReached(routeStop));
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFail();
  }
};

const stopDetailsReducer = stopDetailsSlice.reducer;
export default stopDetailsReducer;
