import { DocumentScanner } from "opencv-document-scanner";

let documentScanner: DocumentScanner;

export const processDocument = (file: File) : Promise<File> => {
  // @ts-ignore-next-line
  const cv = window.cv;
  if (!documentScanner) {
    documentScanner = new DocumentScanner();
  }
  
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      try {
        // Step 1: Crop the document
        const doc = documentScanner.crop(img);
      
        // Resize
        const resized = new cv.Mat();
        const targetHeight = 2400;
        const scaleFactor = targetHeight / doc.height;
        const newWidth = Math.round(doc.width * scaleFactor);
        cv.resize(cv.imread(doc), resized, new cv.Size(newWidth, targetHeight), 0, 0, cv.INTER_AREA);
        
        // Step 2: Convert to grayscale
        const src = cv.imread(doc);
        const gray = new cv.Mat();
        cv.cvtColor(resized, gray, cv.COLOR_BGR2GRAY);
      
        // Step 3: Apply Gaussian Blur
        const blurred = new cv.Mat();
        cv.GaussianBlur(gray, blurred, new cv.Size(7, 7), 0);
      
        // Step 4: Morphological Close Operation
        const kernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(9, 9));
        const closed = new cv.Mat();
        cv.morphologyEx(blurred, closed, cv.MORPH_CLOSE, kernel);
      
        // Step 5: Edge Detection (Optional for Debugging)
        const edges = new cv.Mat();
        cv.Canny(closed, edges, 0, 84);
        // Optional Debugging Visualization: Uncomment to see edges
        // cv.imshow('EdgesCanvas', edges);
      
        // Step 6: Sharpen the Image
        const sharpen = new cv.Mat();
        const blurredSharpen = new cv.Mat();
        cv.GaussianBlur(gray, blurredSharpen, new cv.Size(0, 0), 3);
        cv.addWeighted(gray, 1.5, blurredSharpen, -0.5, 0, sharpen);
      
        // Step 7: Adaptive Thresholding
        const bw = new cv.Mat();
        cv.adaptiveThreshold(
          sharpen,
          bw,
          255,
          cv.ADAPTIVE_THRESH_GAUSSIAN_C,
          cv.THRESH_BINARY,
          21,
          15
        );
      
        // Step 8: Convert the processed image back to a Blob
        const outputCanvas = document.createElement('canvas');
        cv.imshow(outputCanvas, bw);
        
        doc.toBlob((blob) => {
          console.log("input", blob)
        })
        outputCanvas.toBlob((blob) => {
          console.log("output", blob);
          if (blob) {
            // Convert Blob to File for further use
            const bwFile = new File([blob], file.name, { type: file.type });
            resolve(bwFile);
          } else {
            reject(new Error("Failed to process the image into a Blob."));
          }
        });
      
        // Cleanup memory
        src.delete();
        gray.delete();
        blurred.delete();
        kernel.delete();
        closed.delete();
        edges.delete();
        sharpen.delete();
        blurredSharpen.delete();
        bw.delete();
      } catch (error) {
        reject(error);
      }
      
    };
  });
};